import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FlexLayoutModule, SERVER_TOKEN } from '@angular/flex-layout';
// import { FlexLayoutServerModule } from '@angular/flex-layout/server';
import { FaqComponent } from './faq/faq.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DataDeletionInstructionsComponent } from './data-deletion-instructions/data-deletion-instructions.component';
import { RiveModule } from 'ng-rive';
import { ImageOverRoundedGradientComponent } from './image-over-rounded-gradient/image-over-rounded-gradient.component';
import { TitleDescriptionDownloadComponent } from './title-description-download/title-description-download.component';
import { BulkComponent } from './bulk/bulk.component';
import { CustomerTestimonialComponent } from './customer-testimonial/customer-testimonial.component';
// import { EmailSignUpComponent } from './email-sign-up/email-sign-up.component';

const firebaseConfig = {
  apiKey: "AIzaSyAVLWKgmHn4mDpAjv3X718_w68v_vHNYtI",
  authDomain: "livin-website-5c1e5.firebaseapp.com",
  projectId: "livin-website-5c1e5",
  storageBucket: "livin-website-5c1e5.appspot.com",
  messagingSenderId: "541189225291",
  appId: "1:541189225291:web:3472cc720589bf79d786ce",
  measurementId: "G-9XFSZVGJR8"
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactUsComponent,
    FaqComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    DataDeletionInstructionsComponent,
    ImageOverRoundedGradientComponent,
    TitleDescriptionDownloadComponent,
    BulkComponent,
    CustomerTestimonialComponent,
    // EmailSignUpComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgxCaptchaModule,
    MatStepperModule,
    MatIconModule,
    FlexLayoutModule,
    // FlexLayoutServerModule,
    MatSidenavModule,
    MatExpansionModule,
    RiveModule,
    MatProgressSpinnerModule,
  ],
  exports: [

  ],
  providers: [{provide: SERVER_TOKEN, useValue: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
