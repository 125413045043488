<div class="sm:tw-hidden">
  <div class="textCenter tw-flex">
    <img src="../../assets/held_livingmail_no_bg.png" alt="LIVINGMAIL Postcard"/>
  </div>
  
  <div class="tw-text-left tw-mb-14">
    <h1 class="mobileTitle tw-pb-5 tw-font-medium tw-text-white tw-py-1">Send your photos on real cards. It's quick, easy and affordable.</h1>
  </div>
</div>

<div class="sm:tw-hidden">
  <div class="appStoreButtons tw-items-center" [ngClass.lt-sm]="['appStoreButtonsSmall']">
    <span class="imageHelper">
      <a href="https://apps.apple.com/us/app/livingprint/id1499908422" target="_blank"
        style="display: inline-block; overflow: hidden; border-radius: 13px; width: 180px; height: 60px;"><img
          src="../../assets/app_store_button.svg" alt="Download on the App Store"
          style="border-radius: 13px; width: 180px; height: 60px;" onclick="trackIOSConversion()"></a>
    </span>
    <div id="appStoreButtonSpacer"></div>
    <span class="imageHelper">
      <a href='https://play.google.com/store/apps/details?id=com.livingmail.app'
        target="_blank"><img style="height: 60px" alt='Get it on Google Play'
          src='../assets/google_play_button.png' onclick="trackAndroidConversion()"></a>
    </span>
  </div>
</div>

<div class="pb-3 pt-3 tw-text-center align-items-center textCenter">
  <video [muted]="'muted'" autoplay loop controls id="demoVideo">
    <source src="../../assets/LivingPrint_Explainer_Reduced_Size.mp4" type="video/mp4">
  </video>
</div>

<!-- <mat-icon class="tw-pt-9 tw-animate-bounce tw-text-3xl" (click)="scrollDown()">keyboard_arrow_down</mat-icon> -->

<div #section1 class="mx-1 textCenter">
  <div class="row w-100 align-items-center px-0 pt-0 pb-3 py-lg-4 g-0 justify-content-around maxWidth1200 tw-opacity-0" [ngClass]="{'tw-animate-fadeInUp' : hasSection1ScrolledIntoView}">
    <div class="col-12 col-lg-6 mb-2 text-start">
      <app-title-description-download [titleText]=titleText1 [subtitleText]=subtitleText1 [descriptionText]=descriptionText1></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath1 [imageAltText]=imageAltText1></app-image-over-rounded-gradient>
    </div>
  </div>

  <div #section2 class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 flex-row-reverse flex-lg-row justify-content-around maxWidth1200 tw-opacity-0" [ngClass]="{'tw-animate-fadeInUp' : hasSection2ScrolledIntoView}">
    <div class="col-12 col-lg-6 mb-2 text-start order-lg-1">
      <app-title-description-download [titleText]=titleText2 [subtitleText]=subtitleText2 [descriptionText]=descriptionText2></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath2 [imageAltText]=imageAltText2></app-image-over-rounded-gradient>
    </div>
  </div>

  <div #section3 class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 justify-content-around maxWidth1200 tw-opacity-0" [ngClass]="{'tw-animate-fadeInUp' : hasSection3ScrolledIntoView}">
    <div class="col-12 col-lg-6 mb-2 text-start">
      <app-title-description-download [titleText]=titleText3 [subtitleText]=subtitleText3 [descriptionText]=descriptionText3></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath3 [imageAltText]=imageAltText3></app-image-over-rounded-gradient>
    </div>
  </div>

  <div #section4 class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 flex-row-reverse flex-lg-row justify-content-around maxWidth1200 tw-opacity-0" [ngClass]="{'tw-animate-fadeInUp' : hasSection4ScrolledIntoView}">
    <div class="col-12 col-lg-6 mb-2 text-start order-lg-1">
      <app-title-description-download [titleText]=titleText4 [subtitleText]=subtitleText4 [descriptionText]=descriptionText4></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath4 [imageAltText]=imageAltText4></app-image-over-rounded-gradient>
    </div>
  </div>

  <div #section5 class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 justify-content-around maxWidth1200 tw-opacity-0" [ngClass]="{'tw-animate-fadeInUp' : hasSection5ScrolledIntoView}">
    <div class="col-12 col-lg-6 mb-2 text-start">
      <app-title-description-download [titleText]=titleText5 [subtitleText]=subtitleText5 [descriptionText]=descriptionText5></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath5 [imageAltText]=imageAltText5></app-image-over-rounded-gradient>
    </div>
  </div>

  <div #section6 class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 flex-row-reverse flex-lg-row justify-content-around maxWidth1200 tw-opacity-0" [ngClass]="{'tw-animate-fadeInUp' : hasSection6ScrolledIntoView}">
    <div class="col-12 col-lg-6 mb-2 text-start order-lg-1">
      <app-title-description-download [titleText]=titleText6 [subtitleText]=subtitleText6 [descriptionText]=descriptionText6></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath6 [imageAltText]=imageAltText6></app-image-over-rounded-gradient>
    </div>
  </div>

  <div #section7 class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 justify-content-around maxWidth1200 tw-opacity-0" [ngClass]="{'tw-animate-fadeInUp' : hasSection7ScrolledIntoView}">
    <div class="col-12 col-lg-6 mb-2 text-start">
      <app-title-description-download [titleText]=titleText7 [subtitleText]=subtitleText7 [descriptionText]=descriptionText7></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath7 [imageAltText]=imageAltText7></app-image-over-rounded-gradient>
    </div>
  </div>

  <div #section8 class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 flex-row-reverse flex-lg-row justify-content-around maxWidth1200 tw-opacity-0" [ngClass]="{'tw-animate-fadeInUp' : hasSection8ScrolledIntoView}">
    <div class="col-12 col-lg-6 mb-2 text-start order-lg-1">
      <app-title-description-download [titleText]=titleText8 [subtitleText]=subtitleText8 [descriptionText]=descriptionText8></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath8 [imageAltText]=imageAltText8></app-image-over-rounded-gradient>
    </div>
  </div>

  <div class="pb-3 tw-text-center align-items-center textCenter">
    <div class="livingprintLogoWrapper textCenter">
      <img src="../../assets/livingprintLogo.png" class="livingprintLogo" />
      <h1 class="titleText">LIVING<b>PRINT</b><sup>®</sup></h1>
      <h2 class="subtitleText mb-3">BRING YOUR MAIL TO LIFE<sup>®</sup></h2>
    </div>
    <div class="appStoreButtons" [ngClass.lt-sm]="['appStoreButtonsSmall']">
      <span class="imageHelper">
        <a href="https://apps.apple.com/us/app/livingprint/id1499908422" target="_blank"
          style="display: inline-block; overflow: hidden; border-radius: 13px; width: 180px; height: 60px;"><img
            src="../../assets/app_store_button.svg" alt="Download on the App Store"
            style="border-radius: 13px; width: 180px; height: 60px;" onclick="trackIOSConversion()"></a>
      </span>
      <div id="appStoreButtonSpacer"></div>
      <span class="imageHelper">
        <a href='https://play.google.com/store/apps/details?id=com.livingmail.app'
          target="_blank"><img style="height: 60px" alt='Get it on Google Play'
            src='../assets/google_play_button.png' onclick="trackAndroidConversion()"></a>
      </span>
    </div>
    <div class="spacer"></div>
    <div class="spacer"></div>
  </div>

  <h3 class="mb-3">Customer Testimonials</h3>
  <div class="mb-4 g-3 row row-cols-md-2 row-cols-xl-3 maxWidth1200">
    <app-customer-testimonial class="col" [customerTestimonial]=customerTestimonial1 [customerInitialsAndLocation]=customerInitialsAndLocation1></app-customer-testimonial>
    <app-customer-testimonial class="col" [customerTestimonial]=customerTestimonial2 [customerInitialsAndLocation]=customerInitialsAndLocation2></app-customer-testimonial>
    <app-customer-testimonial class="col" [customerTestimonial]=customerTestimonial3 [customerInitialsAndLocation]=customerInitialsAndLocation3></app-customer-testimonial>
    <app-customer-testimonial class="col" [customerTestimonial]=customerTestimonial4 [customerInitialsAndLocation]=customerInitialsAndLocation4></app-customer-testimonial>
    <app-customer-testimonial class="col" [customerTestimonial]=customerTestimonial5 [customerInitialsAndLocation]=customerInitialsAndLocation5></app-customer-testimonial>
    <app-customer-testimonial class="col" [customerTestimonial]=customerTestimonial6 [customerInitialsAndLocation]=customerInitialsAndLocation6></app-customer-testimonial>
    <app-customer-testimonial class="col" [customerTestimonial]=customerTestimonial7 [customerInitialsAndLocation]=customerInitialsAndLocation7></app-customer-testimonial>
    <app-customer-testimonial class="col" [customerTestimonial]=customerTestimonial8 [customerInitialsAndLocation]=customerInitialsAndLocation8></app-customer-testimonial>
  </div>

</div>
