<div class="pageWrapper">
  <div class="spacer"></div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          How is this different than just sending a regular postcard?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p class="bodyText">There are three key differences between a LIVINGPRINT and a standard postcard:</p>
        <p class="bodyText">1. It is custom printed with your own photos and videos.</p>
        <p class="bodyText">2. We do the printing and sending for you so you never have to go to the store to get the
          postcard or the post office to mail it.</p>
        <p class="bodyText">3. It includes a personalized slideshow using your photos, videos, and your choice of music
          which allows you to share much more than just a few photos like a standard postcard.</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Why does it cost money if I can just send photos and videos via Text or Email for free?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>The cost is due to the physical postcard, which we are custom printing, mailing, and tracking for you. Our goal
          is to not only make this easier, but more cost effective than buying a card at the store, paying for postage,
          and mailing this yourself.</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          What does “Bring your mail to life” mean?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>When you scan a LIVINGPRINT we launch a custom digital experience on your device which allows you to see these
          photos and videos in motion paired with music which really adds that extra layer to the experience. This
          experience “Brings your mail to life”.</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Isn't this the same as other services like Postagram™?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>No, our service goes a step above and beyond a custom postcard by including a digital slideshow and music
          experience with every postcard free of charge, it is as simple as scanning the QRCode.</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          How will I know if the person(s) I sent the LIVINGPRINT™ ever got it?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>We offer detailed tracking of your postcard(s) included with each order so you know right when they are
          printed, mailed, along the way, or delivered. We even let you know when the recipient scans the QRCode and
          watches your LIVINGPRINT slideshow!</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Does the LIVINGPRINT app cost anything to download?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>No, the LIVINGPRINT app is completely free to download on both the Apple App Store and the Google Play Store.
        </p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Does a recipient have to download the LIVINGPRINT app to view their LIVINGPRINT photos and videos?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Yes, the app is required to view the LIVINGPRINT Slideshow. (This is because it is not a video, it is a custom
          built Flutter player that allows us to add more interactive experiences. Keep an eye out for custom interactive
          features in the future!)</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          I only have an iPhone, can I still send a LIVINGPRINT?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Yes! Download the LIVINGPRINT app from the Apple App Store on your iPhone and enjoy sending LIVINGPRINT!</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          I only have an Android, can I still send a LIVINGPRINT?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Yes! Download the LIVINGPRINT app from the Google Play Store on your Android device and enjoy sending
          LIVINGPRINT!</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Do I have to have an Account to send a LIVINGPRINT?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>No, you can send and receive LIVINGPRINT without ever creating an account. However, we do recommend you create
          an account because it adds many cool features such as free tracking, history of sent and received LIVINGPRINT so
          you can always view them again later, and even the ability to save recipients so you do not have to type in
          their information again in the future!</p>
      </div>
    </mat-expansion-panel>
    <!-- <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Question
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Answer</p>
      </div>
    </mat-expansion-panel> -->
  </mat-accordion>
  <div class="spacer"></div>
</div>
