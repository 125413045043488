import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  imagePath1 = '../../assets/background_1_iphone_tilt_right.webp';
  imageAltText1 = 'Background page on a phone tilted right';
  titleText1 = 'Send Actual Physical Postcards from Your Phone.';
  subtitleText1 = 'We Print Your Custom Postcard Design and Mail it to Your Recipient(s)';
  descriptionText1 = 'The LIVINGPRINT app lets you create custom designs with your photos and videos and put them onto prints. Each print comes standard with a QR Code. Once it is scanned your recipient will experience the custom-made slideshow you designed for them with your photos, videos, and music to tie it all together into one great experience.';

  imagePath2 = '../../assets/asset_selection_2_iphone_tilt_left.webp';
  imageAltText2 = 'Asset selection page on a phone tilted left';
  titleText2 = 'Step 1:';
  subtitleText2 = 'Choose Your Own Photos & Videos.';
  descriptionText2 = 'We let you personalize each card with your own photos and videos! You no longer need to order photo prints separately, or use boring cards with no personalization. Now you can use your own assets, to truly make these cards your own.';

  imagePath3 = '../../assets/layout_3_iphone_tilt_right.webp';
  imageAltText3 = 'Layout page on a phone tilted right';
  titleText3 = 'Step 2:';
  subtitleText3 = 'Pick from One of Our Many Layouts.';
  descriptionText3 = 'Instead of being stuck with a handful of basic layouts, let your imagination come to life. Our large layout selection not only lets you overlay your photos to add a sense of mystery but to also go back to basics with just one image or standard layouts that you know and love. The beauty is you get to make it yours.';

  imagePath4 = '../../assets/background_4_iphone_tilt_left.webp';
  imageAltText4 = 'Background page on a phone tilted left';
  titleText4 = 'Step 3:';
  subtitleText4 = 'Add a Background to Tie it All Together.';
  descriptionText4 = 'What is a quality photo layout without an awesome background to compliment your style and truly tie it all together? If you choose a layout that has some empty space, be sure to fill it in with your favorite color, pattern, or style. Make it yours.';

  imagePath5 = '../../assets/music_5_iphone_tilt_right.webp';
  imageAltText5 = 'Music page on a phone tilted right';
  titleText5 = 'Step 4:';
  subtitleText5 = 'Bring the Emotion with Music.';
  descriptionText5 = 'Don\'t forget about the slideshow! Now that you have an amazing postcard design that you can call your own, it is time to add some depth to the experience. This is kinda our thing... Add music to level up your slideshow and bring emotion to the LIVINGPRINT experience.';

  imagePath6 = '../../assets/edit_6_iphone_tilt_left.webp';
  imageAltText6 = 'Edit page on a phone tilted left';
  titleText6 = 'Step 5:';
  subtitleText6 = 'Choose the Order and Crop Your Pictures and Videos.';
  descriptionText6 = 'We got you here. We know that you may have picked your pictures out of order. Not to worry! You can re-order your images and videos in the \'Edit\' tab. Is your photo cutoff? Go ahead and crop it the way you want, also in the \'Edit\' tab.';

  imagePath7 = '../../assets/message_7_iphone_tilt_right.webp';
  imageAltText7 = 'Message page on a phone tilted right';
  titleText7 = 'Step 6:';
  subtitleText7 = 'Add a Personal Message for Your Recipient(s).';
  descriptionText7 = 'The message on the back of the postcard is one of the most important things! It lets you tell your recipient what is going on, keep in touch, and say hello! Now that you have a completely custom card with a cool slideshow, finish strong by bringing it back to basics with a good old message on the back.';

  imagePath8 = '../../assets/recipient_8_iphone_tilt_left.webp';
  imageAltText8 = 'Recipient page on a phone tilted left';
  titleText8 = 'Step 7:';
  subtitleText8 = 'Tell Us Who To Send It to, Order It, Then We Will Print and Mail It for You!';
  descriptionText8 = 'We want to make this process as affordable, quick, and easy as possible for you. We think sending a nice message on a postcard is a lost art for many. When you open your mailbox you are probably expecting nothing but bills and ads. We are here to make the whole mail experience better.';

  customerTestimonial1 = 'Because I\'m an avid reader of Zipline magazine I recognized the LIVINGMAIL product that I received today in the mail. High-quality individually designed beautifully decorated postcard delivered right to my mailbox with the just the click of a button!!!';
  customerInitialsAndLocation1 = 'C.F. Los Gatos, CA';

  customerTestimonial2 = 'Just scanned it. Thanks sooooo much, I\'m keeping it!';
  customerInitialsAndLocation2 = 'K.H. Paso Robles, CA';

  customerTestimonial3 = 'This is the PERFECT use for LIVINGMAIL! I\'m capturing and sharing with family and friends our experience of 9 days, covering 7 states on ONE postcard for $1.99! And I created it in about two minutes!';
  customerInitialsAndLocation3 = 'K.M. Avila Beach, CA';

  customerTestimonial4 = 'Love getting the postcards, it\'s like we were kind of there and didn\'t miss everything. Thank you.';
  customerInitialsAndLocation4 = 'D.K. Newhall, CA';

  customerTestimonial5 = 'You are SO clever, loved the slideshow enough to download the app and look forward to using it next time.';
  customerInitialsAndLocation5 = 'S.B. Pismo Beach, CA';

  customerTestimonial6 = 'Just got my LIVINGMAIL experience - love it! So well done, really love the innovative approach integrating Direct Mail & Digital!';
  customerInitialsAndLocation6 = 'K.D. San Luis Obispo, CA';

  customerTestimonial7 = 'Combines real mail with technology! Genius!';
  customerInitialsAndLocation7 = 'J.L. Huntington Beach, CA';

  customerTestimonial8 = 'We got the postcard!!! And the girls watched the video slideshow on repeat! So cute thank you! Everything on the app worked great.';
  customerInitialsAndLocation8 = 'K.M., San Diego, CA';

  public isBrowser = isPlatformBrowser(this.platformId);

  constructor(private title: Title, private meta: Meta, @Inject(PLATFORM_ID) private platformId: any, public analytics: AngularFireAnalytics) {}

  @ViewChild('section1', { static: false })
  section1: ElementRef<HTMLDivElement> | undefined;
  hasSection1ScrolledIntoView: boolean = false;

  @ViewChild('section2', { static: false })
  section2: ElementRef<HTMLDivElement> | undefined;
  hasSection2ScrolledIntoView: boolean = false;

  @ViewChild('section3', { static: false })
  section3: ElementRef<HTMLDivElement> | undefined;
  hasSection3ScrolledIntoView: boolean = false;

  @ViewChild('section4', { static: false })
  section4: ElementRef<HTMLDivElement> | undefined;
  hasSection4ScrolledIntoView: boolean = false;

  @ViewChild('section5', { static: false })
  section5: ElementRef<HTMLDivElement> | undefined;
  hasSection5ScrolledIntoView: boolean = false;

  @ViewChild('section6', { static: false })
  section6: ElementRef<HTMLDivElement> | undefined;
  hasSection6ScrolledIntoView: boolean = false;

  @ViewChild('section7', { static: false })
  section7: ElementRef<HTMLDivElement> | undefined;
  hasSection7ScrolledIntoView: boolean = false;

  @ViewChild('section8', { static: false })
  section8: ElementRef<HTMLDivElement> | undefined;
  hasSection8ScrolledIntoView: boolean = false;

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView() {
    if (this.isBrowser) {
      if (this.section1 && !this.hasSection1ScrolledIntoView) {
        const rect = this.section1.nativeElement.getBoundingClientRect();
        this.hasSection1ScrolledIntoView = rect.top <= window.innerHeight - 200;
      }
  
      if (this.section2 && !this.hasSection2ScrolledIntoView) {
        const rect = this.section2.nativeElement.getBoundingClientRect();
        this.hasSection2ScrolledIntoView = rect.top <= window.innerHeight - 200;
      }
  
      if (this.section3 && !this.hasSection3ScrolledIntoView) {
        const rect = this.section3.nativeElement.getBoundingClientRect();
        this.hasSection3ScrolledIntoView = rect.top <= window.innerHeight - 200;
      }
  
      if (this.section4 && !this.hasSection4ScrolledIntoView) {
        const rect = this.section4.nativeElement.getBoundingClientRect();
        this.hasSection4ScrolledIntoView = rect.top <= window.innerHeight - 200;
      }
  
      if (this.section5 && !this.hasSection5ScrolledIntoView) {
        const rect = this.section5.nativeElement.getBoundingClientRect();
        this.hasSection5ScrolledIntoView = rect.top <= window.innerHeight - 200;
      }
  
      if (this.section6 && !this.hasSection6ScrolledIntoView) {
        const rect = this.section6.nativeElement.getBoundingClientRect();
        this.hasSection6ScrolledIntoView = rect.top <= window.innerHeight - 200;
      }
  
      if (this.section7 && !this.hasSection7ScrolledIntoView) {
        const rect = this.section7.nativeElement.getBoundingClientRect();
        this.hasSection7ScrolledIntoView = rect.top <= window.innerHeight - 200;
      }
  
      if (this.section8 && !this.hasSection8ScrolledIntoView) {
        const rect = this.section8.nativeElement.getBoundingClientRect();
        this.hasSection8ScrolledIntoView = rect.top <= window.innerHeight - 200;
      }
    }
  }

  trackIOSConversion() {
    this.analytics.logEvent('app_store_visit');
  }

  trackAndroidConversion() {
    this.analytics.logEvent('play_store_visit');
  }

  scrollDown() {
    if (this.isBrowser) {
      if (this.section1 !== undefined) {
        window.scrollTo(0, this.section1.nativeElement.getBoundingClientRect().top -20);
      } else {
        window.scrollBy(0, 650);
      }
    }
  }

  ngOnInit(): void {
    this.title.setTitle('LIVINGPRINT - Home - Send Actual Physical Postcards from Your Phone!');
    this.meta.addTags([
      { name: 'description', content: 'Send Postcards from Your Phone! LIVINGPRINT Lets You Design, Create, and Mail Postcards Directly From the App. Send Birthday Postcards, Thank You Postcards, Holiday Postcards, Christmas Postcards, etc...' }
    ]);
    if (this.isBrowser) {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
      window.scrollTo(0, 1);
    }
  }

}
