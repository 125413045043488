import { INPUT_MODALITY_DETECTOR_OPTIONS } from '@angular/cdk/a11y';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-over-rounded-gradient',
  templateUrl: './image-over-rounded-gradient.component.html',
  styleUrls: ['./image-over-rounded-gradient.component.scss']
})
export class ImageOverRoundedGradientComponent implements OnInit {

  @Input() imagePath: string = '';
  @Input() imageAltText: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
