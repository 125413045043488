<div class="pageWrapper">
  <div class="textWrapper">
    <h1 class="titleText">About</h1>
    <h2 class="subtitleText">Create digital experiences on postcards with LIVINGPRINT!</h2>
    <div class="textSpacer"></div>
    <p class="bodyText">LIVINGPRINT is an exciting new technology that allows you to create and interact electronically
      with physical postcards! Using our LIVINGPRINT mobile application you can create and send personalized postcards
      that include exciting and interactive digital experiences that Bring Your Mail to Life!</p>
    <div class="textSpacer"></div>
    <div class="textSpacer"></div>
    <p class="subtitleText">CREATE A LIVINGPRINT POSTCARD IN 3-EASY STEPS:</p>
    <mat-horizontal-stepper #stepper fxHide.lt-md="true">
      <mat-step label="Create">
        <div class="textSpacer"></div>
        <mat-icon class="stepIcon">add</mat-icon>
        <div class="textSpacer"></div>
        <p class="bodyText aboutStepperText">Create personalized postcards by selecting your photos and videos from your mobile device using the
          LIVINGPRINT App.</p>
        <div>
          <button mat-flat-button color="accent" matStepperNext>Next</button>
        </div>
      </mat-step>
      <mat-step label="Customize">
        <div class="textSpacer"></div>
        <mat-icon class="stepIcon">edit</mat-icon>
        <div class="textSpacer"></div>
        <p class="bodyText aboutStepperText">Customize the postcard and slideshow however you would like using our professionally created templates and
          themes, including background music.</p>
        <div class="stepperButtonWrapper">
          <button mat-flat-button color="accent" matStepperPrevious>Back</button>
          <div class="spacer"></div>
          <button mat-flat-button color="accent" matStepperNext>Next</button>
        </div>
      </mat-step>
      <mat-step label="Order">
        <div class="textSpacer"></div>
        <mat-icon class="stepIcon">shopping_cart</mat-icon>
        <div class="textSpacer"></div>
        <p class="bodyText aboutStepperText">We will print and send the physical postcard to whomever you want throughout the United States for only
          $1.99!</p>
        <div class="stepperButtonWrapper">
          <button mat-flat-button color="accent" matStepperPrevious>Back</button>
          <div class="spacer"></div>
          <button mat-flat-button color="accent" (click)="stepper.reset()">Reset</button>
        </div>
      </mat-step>

      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>

    </mat-horizontal-stepper>

    <mat-vertical-stepper #stepper fxHide.gt-sm="true">
      <mat-step label="Create">
        <div class="textSpacer"></div>
        <mat-icon class="stepIcon">add</mat-icon>
        <div class="textSpacer"></div>
        <p class="bodyText aboutStepperText">Create personalized postcards by selecting your photos and videos from your mobile device using the
          LIVINGPRINT App.</p>
        <div>
          <button mat-flat-button color="accent" matStepperNext>Next</button>
        </div>
      </mat-step>
      <mat-step label="Customize">
        <div class="textSpacer"></div>
        <mat-icon class="stepIcon">edit</mat-icon>
        <div class="textSpacer"></div>
        <p class="bodyText aboutStepperText">Customize the postcard and slideshow however you would like using our professionally created templates and
          themes, including background music.</p>
        <div class="stepperButtonWrapper">
          <button mat-flat-button color="accent" matStepperPrevious>Back</button>
          <div class="spacer"></div>
          <button mat-flat-button color="accent" matStepperNext>Next</button>
        </div>
      </mat-step>
      <mat-step label="Order">
        <div class="textSpacer"></div>
        <mat-icon class="stepIcon">shopping_cart</mat-icon>
        <div class="textSpacer"></div>
        <p class="bodyText aboutStepperText">We will print and send the physical postcard to whomever you want throughout the United States for only
          $1.99!</p>
        <div class="stepperButtonWrapper">
          <button mat-flat-button color="accent" matStepperPrevious>Back</button>
          <!-- <div class="spacer"></div> -->
          <!-- <button mat-flat-button color="accent" (click)="stepper.reset()">Reset</button> -->
        </div>
      </mat-step>

      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>

    </mat-vertical-stepper>
    <div class="textSpacer"></div>
    <div class="textSpacer"></div>
    <p class="bodyText">Just like that, you have sent a physical postcard to friends and family sharing the exciting and
      tangible experience of receiving a physical piece of mail combined with a digital experience.</p>
    <div class="textSpacer"></div>
    <p class="bodyText">Once the postcard arrives, the recipient can “Bring The Mail to Life” by scanning the Unique QR
      Code on the postcard and watching the slideshow of your photos and videos while listening to the background music.
      By scanning the QR Code on the postcard, the recipient experiences the wonderful slideshow you created for them
      adding excitement, creativity and a true elemental <i>experience</i> to mail!</p>
    <div class="textSpacer"></div>
    <p class="bodyText">LIVINGPRINT combines many things you already know and love into one easy, seamless and
      unforgettable experience. LIVINGPRINT bundles the joy of mail, slideshows, videos, and creativity into one FREE
      app.</p>
    <div class="textSpacer"></div>
    <p class="bodyText">This is an unparalleled digital and mail experience like never before. This experience is what
      we call LIVINGPRINT!</p>
    <div class="textSpacer"></div>
  </div>
</div>