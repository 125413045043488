import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BulkComponent } from './bulk/bulk.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DataDeletionInstructionsComponent } from './data-deletion-instructions/data-deletion-instructions.component';
// import { EmailSignUpComponent } from './email-sign-up/email-sign-up.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from "./home/home.component";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'bulk', component: BulkComponent },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'data-deletion-instructions', component: DataDeletionInstructionsComponent },
  // { path: 'email-sign-up', component: EmailSignUpComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
