import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bulk',
  templateUrl: './bulk.component.html',
  styleUrls: ['./bulk.component.scss']
})
export class BulkComponent implements OnInit {

  imagePath1 = '../../assets/printer_printing.webp';
  imageAltText1 = 'Printer pringing pages';
  titleText1 = 'Are Your Customers Tired of Sending Static Postcards?';
  subtitleText1 = 'Take Direct Mail to the Next Level by Making a Commercial on a Postcard in Less Than 2-Minutes for Free!';
  descriptionText1 = 'Stand out from the regular (ahem, old school) printing crowd. Set your business apart by offering your customers bulk LIVINGMAIL® Postcards from LIVINGPRINT. Our LIVINGPRINT App can integrate photos and videos, along with a custom background and music soundtrack to create print-ready artwork that can be printed with variable data or sent as an EDDM. Your customers can create their LIVINGMAIL Postcards in minutes, then using the steps detailed below you will receive the PDF to use for their mailing. No added work or labor on your side compared to a standard postcard.';

  imagePath2 = '../../assets/step_one_download_2_iphone_tilt_left.webp';
  imageAltText2 = 'LIVINGPRINT App Store Listing';
  titleText2 = 'Step 1:';
  subtitleText2 = 'Download the LIVINGPRINT App';
  descriptionText2 = 'Navigate to the App Store® or Google Play™ store on your device and download LIVINGPRINT.';

  imagePath3 = '../../assets/step_two_add_bulk_print_shop_3_iphone_tilt_right.webp';
  imageAltText3 = 'Create LIVINGMAIL Postcard';
  titleText3 = 'Step 2:';
  subtitleText3 = 'Add A Bulk Print Shop';
  descriptionText3 = 'Open the LIVINGPRINT app and create an account or log in. Once you are logged in go to settings and select "Add Bulk Print Shop". Enter your print shop\'s six digit code which you can get from your print shop or from their flyer they provided.';

  imagePath4 = '../../assets/step_three_create_4_iphone_tilt_left.webp';
  imageAltText4 = 'Add print shop info';
  titleText4 = 'Step 3:';
  subtitleText4 = 'Create Your LIVINGMAIL Postcard';
  descriptionText4 = 'Tap the "Create" button located in the bottom center of the app bar. Follow the steps in app to create your custom LIVINGMAIL Postcard with your photos and videos. When you get to the "Add Recipients" page, check the "Create a Bulk Order" box and choose the bulk provider you would like to place the order with. Then finish creating your card and place your order.';

  imagePath5 = '../../assets/step_four_screenshot_5_iphone_tilt_right.webp';
  imageAltText5 = 'Screenshot your confirmed order';
  titleText5 = 'Step 4:';
  subtitleText5 = 'Get In Contact With Your Print Shop';
  descriptionText5 = 'Your print shop will receive an order confirmation email with your email address in it. Keep an eye out for an email from them, or reach out to them directly.';

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('LIVINGPRINT - Bulk - Are Your Customers Tired of Sending Static Postcards?');
    this.meta.addTags([
      { name: 'description', content: 'Take Direct Mail to the Next Level by Making a Commercial on a Postcard in Less Than 2-Minutes and for Less Than $2.00! Stand out from the regular (ahem, old school) printing crowd. Set your business apart by offering your customers bulk LIVINGMAIL® Postcards from LIVINGPRINT. Our LIVINGPRINT can integrate your photos and videos, along with a custom background and music soundtrack to create camera-ready artwork that you can print variable data or send as an EDDM. Your customers can create their LIVINGMAIL Postcards in minutes, then using the steps detailed below you will receive the PDF to use for their mailing. No added work or labor on your side compared to a standard postcard.' }
    ]);
  }

}
