import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('LIVINGPRINT - FAQ - Have Some Questions About LIVINGPRINT?');
    this.meta.addTags([
      { name: 'description', content: 'Visit our FAQ to read up on our frequently asked questions so you can learn more about LIVINGPRINT! Answer questoins like How is LIVINGMAIL different than just sending a regular postcard?' }
    ]);
  }

}
