import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('LIVINGPRINT - Privacy Policy');
    this.meta.addTags([
      { name: 'description', content: 'Learn about LIVINGPRINT\'s Privacy Policy.' }
    ]);
  }

}
