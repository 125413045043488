<div class="mx-1 textCenter">
  <div class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 justify-content-around maxWidth1200">
    <div class="col-12 col-lg-6 mb-2 text-start">
      <app-title-description-download [titleText]=titleText1 [subtitleText]=subtitleText1
        [descriptionText]=descriptionText1></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <img src="{{imagePath1}}" alt="{{imageAltText1}}" />
    </div>
  </div>

  <div class="d-flex justify-content-center tw-pb-20">
    <a href="https://onboarding.livingprint.com/" target="_blank">
      <button
        class="bg-primary hover:tw-scale-105 tw-transition-all tw-text-white tw-font-bold tw-mt-10 tw-py-6 tw-px-6 tw-rounded">
        Click here to onboard and get started today!
      </button>
    </a>
  </div>

  <div class="d-flex justify-content-center">
    <h3 class="px-1 pt-lg-2 text-start d-flex">4 Easy Steps for Your Customers to Follow:</h3>
  </div>

  <div
    class="row w-100 align-items-center px-0 pt-2 pb-3 pt-lg-2 pb-lg-4 g-0 flex-row-reverse flex-lg-row justify-content-around maxWidth1200">
    <div class="col-12 col-lg-6 mb-2 text-start order-lg-1">
      <app-title-description-download [titleText]=titleText2 [subtitleText]=subtitleText2
        [descriptionText]=descriptionText2></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath2 [imageAltText]=imageAltText2>
      </app-image-over-rounded-gradient>
    </div>
  </div>

  <div class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 justify-content-around maxWidth1200">
    <div class="col-12 col-lg-6 mb-2 text-start">
      <app-title-description-download [titleText]=titleText3 [subtitleText]=subtitleText3
        [descriptionText]=descriptionText3></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath3 [imageAltText]=imageAltText3>
      </app-image-over-rounded-gradient>
    </div>
  </div>

  <div
    class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 flex-row-reverse flex-lg-row justify-content-around maxWidth1200">
    <div class="col-12 col-lg-6 mb-2 text-start order-lg-1">
      <app-title-description-download [titleText]=titleText4 [subtitleText]=subtitleText4
        [descriptionText]=descriptionText4></app-title-description-download>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath4 [imageAltText]=imageAltText4>
      </app-image-over-rounded-gradient>
    </div>
  </div>

  <div class="row w-100 align-items-center px-0 pt-2 pb-3 py-lg-4 g-0 justify-content-around maxWidth1200">
    <div class="col-12 col-lg-6 mb-2 text-start">
      <app-title-description-download [titleText]=titleText5 [subtitleText]=subtitleText5
        [descriptionText]=descriptionText5></app-title-description-download>
        <div class="tw-w-full textCenter">
          <a href="https://firebasestorage.googleapis.com/v0/b/livin-website-5c1e5.appspot.com/o/livingprintBulkFlyer_2023-07-14.pdf?alt=media&token=68e8ea54-94d9-4a1d-9ad2-267946a39d71"
            target="_blank">
            <button
              class="bg-primary hover:tw-scale-105 tw-transition-all tw-text-white tw-font-bold tw-mt-10 tw-py-3 tw-px-4 tw-rounded">
              Download Sample Flyer
            </button>
          </a>
        </div>
    </div>
    <div class="col-12 col-lg-6 mb-2 my-2 textCenter">
      <app-image-over-rounded-gradient [imagePath]=imagePath5 [imageAltText]=imageAltText5>
      </app-image-over-rounded-gradient>
    </div>
  </div>
</div>

<div class="roundedContainerTeal d-flex flex-column text-white mx-auto mb-2">
  <div class="row justify-content-center">
    <div class="col-auto d-flex align-items-center">
      <img class="livingprintLogo my-auto" src="../../assets/livingprintLogo.png" alt="LIVINGPRINT Logo" />
    </div>
    <div class="col-auto">
      <h3 class="cardTitle m-0">LIVINGMAIL<br>POSTCARDS</h3>
    </div>
  </div>
  <div class="cardDescription row align-self-center mt-2">
    <p>Create Custom Bulk Interactive Direct<br>Mail Postcard Campaigns in Minutes</p>
  </div>
</div>

<div class="roundedContainerBlue d-flex flex-column text-white mx-auto mb-2 mt-2">
  <div class="row justify-content-center">
    <div class="col-auto" style="flex: 2; min-width: 350px;">
      <p>
        Target customers with custom LIVINGMAIL postcards that create a
        "commercial on a postcard" in a matter of minutes promoting your
        business!
        <br>
        <br>
        How many of your marketing campaigns fail or fall flat because
        they look like everyone else's? Not anymore! We have re-invented
        the postcard and are "Bringing Your Mail to Life®" with LIVINGMAIL!
      </p>
    </div>
    <div class="col-auto">
      <img class="sampleQRCode" src="../../assets/sample_qr_code.png" alt="Sample QR Code of LIVINGMAIL" />
    </div>
  </div>
</div>

<div class="row text-white mx-auto justify-content-md-between" style="max-width: 800px;">
  <div class="roundedContainerTeal square col-auto my-1 mx-auto">
    <h3 class="m-0 text-center">42%</h3>
    <p>of recipients read or scan the direct mail they receive</p>
  </div>
  <div class="roundedContainerTeal square col-auto my-1 mx-auto">
    <h3 class="m-0 text-center">70%</h3>
    <p>of consumers say direct mail is more personal than online interactions</p>
  </div>
  <div class="roundedContainerTeal square col-auto my-1 mx-auto">
    <h3 class="m-0 text-center">86%</h3>
    <p>of people would like to see more video from brands</p>
  </div>
</div>

<div class="roundedContainerTeal text-white mx-auto mt-1">
  <h3 class="containerTitle">LIVINGMAIL = <br>Direct Mail + Slide Show</h3>
  <p>Images, Videos, Music and a Custom Background - The Winning Combination!</p>
</div>

<div class="my-5"></div>

<h3 class="tw-text-center">Bulk LIVINGPRINT FAQ</h3>

<div class="faqWrapper" style="text-align: -webkit-center !important;">
  <mat-accordion class="tw-text-left">

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          What KPI's are available?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Sender receives a notification every time the QR code on the
          LIVINGMAIL postcard is scanned.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Where can I find the onboarding form?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <a class="tw-text-blue-900 hover:tw-opacity-80 hover:tw-text-blue-900" target="_blank"
          href="https://onboarding.livingprint.com/">https://onboarding.livingprint.com/</a>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Can LIVINGMAIL be used with the USPS Informed Delivery Program?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Yes, senders can upload a Representative image of the Postcard and
          include a Ride-Along image and piece with a Target URL.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Can you upload a video to LIVINGMAIL and not just pictures?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Yes, absolutely! The app supports up to 5-minutes of content
          including video, music and photos.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          What email address do I send my questions on LIVINGPRINT and
          LIVINGMAIL to?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>help@livingmail.com</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Once we receive the print ready file can we resize? Can we just
          capture the QR code and place it on a different size piece?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>You will receive a PDF with print-ready art that you can resize. You
          can extract the QR code from the artwork and place it on any other art
          or size piece you would like. It is recommended to screenshot it on the
          PDF received for bulk printing and use it on the new piece to test it for
          scannability based on color, contrast, size, and resolution.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Is there an ability to upload custom backgrounds and music, or to
          edit the layout templates at all? Looks like there's a very nice base to
          start with, curious on the creative flexibility of some of the elements.
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Not at this time. There is currently a huge library of templates with
          backgrounds, music and layouts to choose from and the library is
          constantly growing. If you have design suggestions or ideas for
          additional backgrounds, music and templates, please send those to
          help@livingmail.com</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Can the linked content be edited after the cards are printed?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>No, not at this time. The QR code has an embedded link to the
          LIVINGMAIL slideshow.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          As a choice vendor, can we suggest you create a branded explainer
          video for our 900 locations?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Yes, this has already been done for you! Print Shops can share the
          LIVINGMAIL Explainer Video posted here:
          https://www.youtube.com/watch?v=DUD_qzuNXM8 and embed it into
          their own branded websites and marketing materials. Print Shops are
          also encouraged to do a 'self-promo' video using the LIVINGMAIL app to
          create a video and LIVINGMAIL postcard and to send it to their
          customers promoting their printshop and this new exciting service.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Can the URL that the QR code is set to be updated at a later time? We
          print mail 3 months at a time for a customer.
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Not at this time but this is something we have added to the
          development wish list. We are always open to suggestions and ways to
          improve the service. Please email those suggestions to
          help@livingmail.com</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Does the end user need to download the app to view the slideshow?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>No, we are happy to announce that our Web Player is live! Users who
          scan the QR code that do not have the app installed will be directed
          to the Web Player where they can view the slideshow in their browser.
          For the best experience we do recommend downloading the app.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Do you offer a "self-promo" option where we can record our own
          postcard and mail out?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>You can create a “self-promo” postcard in-house using the
          LIVINGPRINT app for free and mail it out to all of your customers!
          To do this fill out the onboarding form at onboarding.livingprint.com
          if you have not already, then follow the steps on the flyer you
          receive after onboarding. Then you will receive the PDF that
          you can print and mail in bulk.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Does it cost money to design a postcard for a bulk order using the
          LIVINGPRINT app?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>There is no cost to design a postcard using the LIVINGPRINT app for
          bulk orders. As long as it is a bulk order it is completely free to
          create a design and place the bulk order.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Does this tech qualify for the USPS emerging tech postal discounts?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>We have a question pending with the USPS on this. It definitely
          seems that it would qualify for the 2022 Emerging and Advanced
          Technology Promotion with a 2-3% postage discount as the statement
          on this promotion from the USPS is that “This Promotion will help you
          utilize the latest technologies to create an exciting and engaging
          customer experience with the mail.” More information on this promotion
          can be found here. We will report back with our official findings on our
          FAQ page</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Will you have the ability for us to upload directly without the
          customer?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>The upload needs to be done from the LIVINGMAIL app from either
          you or your customer. You could do it on behalf of your customer if
          desired. Some print shops have even discussed going on-site to
          customers' locations and filming and creating the LIVINGMAIL for them
          as part of the service they could charge for as another revenue source.
          Trish Witkowski of FoldFactory stated during the Webinar: “I love the
          idea of offering to help the customer make the best LIVINGMAIL
          postcard they can make. "Send us your photos and videos and we'll put
          it together for you!" or DIY - your choice”</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Will our clients who create an account on LIVINGMAIL be marketed to
          by LIVINGMAIL for individual postcards or additional products?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>They will receive push notifications that we send out and if we begin
          email marketing campaigns for those that have downloaded the
          LIVINGMAIL app then yes. They are not sectioned out in any way as of
          now as a “bulk” user vs a standard user.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Makes me think about informed delivery - is there a way to let them
          know in the image scan/address side that something "alive" and
          exciting is coming that day?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Yes absolutely. You can add that verbiage to the Ride-Along image of
          the Informed Delivery campaign for the piece. AccuZIP has Professional
          Services to help you set up Informed Delivery campaigns. Visit:
          https://www.accuzip.com/products/professional-services/informed-
          delivery-campaign-setup/</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          Does the video tech you are using allow for engagement like at the
          end of the video click here for more info and it leads them to a lead
          gen form?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>Yes, if you are placing a bulk order there is now an optional step
          to add a call to action URL to the video. This can be a lead gen form
          or any other website you wish to direct users to. If you enter a URL
          into the call to action field, a button will appear at the end of the
          video that will direct users to the URL you entered. This is a great
          way to drive traffic to your website or a landing page.</p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header class="expansionPanelHeader">
        <mat-panel-title>
          How long will QR Codes / Videos be up on the server to scan?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expansionPanelBody">
        <p>For now, indefinitely! We host it and stream it as part of the
          service.</p>
      </div>
    </mat-expansion-panel>

  </mat-accordion>
</div>

<!-- <div class="d-flex justify-content-center tw-pb-10 tw-pt-5">
  <a href="https://firebasestorage.googleapis.com/v0/b/livin-website-5c1e5.appspot.com/o/LIVINGPRINT%20Bulk%20Webinar%202022-02-02.pdf?alt=media&token=97e7ca46-a0e0-4e94-86a1-e993ae097748"
    target="_blank">
    <button
      class="bg-primary hover:tw-scale-105 tw-transition-all tw-text-white tw-font-bold tw-mt-5 tw-py-6 tw-px-6 tw-rounded">
      Download the PDF Version of the FAQ
    </button>
  </a>
</div> -->

<div class="my-3"></div>
