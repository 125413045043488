import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-description-download',
  templateUrl: './title-description-download.component.html',
  styleUrls: ['./title-description-download.component.scss']
})
export class TitleDescriptionDownloadComponent implements OnInit {

  @Input() titleText: string = '';
  @Input() subtitleText: string = '';
  @Input() descriptionText: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
