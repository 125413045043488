import { Component } from '@angular/core';
import { Router } from '@angular/router';
import 'bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(public router: Router) {}

  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
