<div class="d-flex flex-column m-auto titleDescriptionContainer">
  <h2>{{titleText}}</h2>
  <h3 *ngIf="subtitleText">{{subtitleText}}</h3>
  <p class="mb-3">{{descriptionText}}</p>
  <div class="appStoreButtons" [ngClass.lt-sm]="['appStoreButtonsSmall']">
    <span class="imageHelper">
      <a href="https://apps.apple.com/us/app/livingprint/id1499908422" target="_blank"
        style="display: inline-block; overflow: hidden; border-radius: 13px; width: 180px; height: 60px;"><img
          src="../../assets/app_store_button.svg" alt="Download on the App Store"
          style="border-radius: 13px; width: 180px; height: 60px;" onclick="trackIOSConversion()"></a>
    </span>
    <div id="appStoreButtonSpacer"></div>
    <span class="imageHelper">
      <a href='https://play.google.com/store/apps/details?id=com.livingmail.app'
        target="_blank"><img style="height: 60px" alt='Get it on Google Play'
          src='../assets/google_play_button.png' onclick="trackAndroidConversion()"></a>
    </span>
  </div>
</div>
