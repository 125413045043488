import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle('LIVINGPRINT - Terms of Use - Terms and Conditions');
    this.meta.addTags([
      { name: 'description', content: 'Learn about LIVINGPRINT\'s Terms of Use and Terms and Conditions.' }
    ]);
  }

}
