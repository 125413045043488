<div class="text-center" id="contactUsFormWrapper" *ngIf="emailSentStatus === Status.notSent || emailSentStatus === Status.failed">
  <h1 class="titleText">Contact us using the form below:</h1>
  <p>Note: All fields are required *</p>
  <p class="errorText" *ngIf="emailSentStatus === Status.failed">There was an error sending your email. Please try again later, we apologize for the inconvenience.</p>
  <div class="emailFormWrapper">
    <form (ngSubmit)="onSubmit()" class="emailForm" name="emailForm" [formGroup]="emailForm">
      <mat-form-field appearance="standard" class="emailFormElement">
        <mat-label>Name</mat-label>
        <input type="text" name="name" matInput [formControl]="name" required>
        <mat-error *ngIf="this.name.invalid">{{getNameErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" class="emailFormElement">
        <mat-label>Email</mat-label>
        <input type="text" name="email" matInput placeholder="name@example.com" [formControl]="email" required>
        <mat-error *ngIf="this.email.invalid">{{getEmailErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" class="emailFormElement">
        <mat-label>Category</mat-label>
        <mat-select name="category" [formControl]="category" required>
          <mat-option value="Support">Support</mat-option>
          <mat-option value="Feedback">Feedback</mat-option>
          <mat-option value="Feature Request">Feature Request</mat-option>
          <mat-option value="Sales Inquiry">Sales Inquiry</mat-option>
        </mat-select>
        <mat-error *ngIf="this.category.invalid">{{getCategoryErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" class="emailFormElement">
        <mat-label>Subject</mat-label>
        <input type="text" name="subject" matInput [formControl]="subject" required>
        <mat-error *ngIf="this.subject.invalid">{{getSubjectErrorMessage()}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard" class="emailFormElement">
        <mat-label>Details</mat-label>
        <textarea name="details" matInput class="detailsField" [formControl]="details" required></textarea>
        <mat-error *ngIf="this.details.invalid">{{getDetailsErrorMessage()}}</mat-error>
      </mat-form-field>
      <div *ngIf="isBrowser" class="recaptchaWrapper" [ngClass.lt-sm]="['recaptchaWrapperSmall']">
        <ngx-recaptcha2 [siteKey]="siteKey" [formControl]="recaptcha"></ngx-recaptcha2>
      </div>
      <div class="emailFormElement emailFormButton w-100">
        <button type="submit" mat-flat-button color="primary" [disabled]="!emailForm.valid">Submit</button>
      </div>
    </form>
  </div>
</div>

<div id="emailSendingWrapper" *ngIf="emailSentStatus === Status.sending">
  <mat-spinner></mat-spinner>
</div>

<div id="riveSuccessAnimationWrapper" class="tw-text-center" *ngIf="emailSentStatus === Status.sent">
  <p class="titleText">Thank you for your email. A member of our LIVINGPRINT team will respond on or before the next business day.</p>
  <canvas riv="email_confirmation_animation" width="300" height="300">
    <riv-animation index=0 play></riv-animation>
  </canvas>
</div>
