import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-deletion-instructions',
  templateUrl: './data-deletion-instructions.component.html',
  styleUrls: ['./data-deletion-instructions.component.scss']
})
export class DataDeletionInstructionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
