import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

enum Status {
  notSent,
  sending,
  sent,
  failed
}

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public isBrowser = isPlatformBrowser(this.platformId)
  siteKey: string;
  Status = Status;
  emailSentStatus = Status.notSent;

  name = new FormControl('', [Validators.required, Validators.maxLength(200)]);
  email = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(200)]);
  category = new FormControl('', [Validators.required]);
  subject = new FormControl('', [Validators.required, Validators.maxLength(75)]);
  details = new FormControl('', [Validators.required, Validators.maxLength(2000)]);
  recaptcha = new FormControl('', [Validators.required]);

  emailForm = this.fb.group({
    name: this.name,
    email: this.email,
    category: this.category,
    subject: this.subject,
    details: this.details,
    recaptcha: this.recaptcha,
  })

  getNameErrorMessage() {
    if (this.name.hasError('required')) {
      return 'Name is required';
    }

    return this.name.hasError('maxlength') ? 'Name cannot exceed 200 characters in length' : '';
  }
  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Email is required';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  getCategoryErrorMessage() {
    return this.category.hasError('required') ? 'Category is required' : '';
  }
  getSubjectErrorMessage() {
    if (this.subject.hasError('required')) {
      return 'Subject is required';
    }

    return this.subject.hasError('maxlength') ? 'Subject cannot exceed 75 characters in length' : '';
  }
  getDetailsErrorMessage() {
    if (this.details.hasError('required')) {
      return 'Details are required';
    }

    return this.details.hasError('maxlength') ? 'Details cannot exceed 2,000 characters in length' : '';
  }

  scrollToTop() {
    window.scrollTo(0,0);
  }

  onSubmit() {
    interface emailFormData {
      name: string,
      email: string,
      category: string,
      subject: string,
      details: string
    }

    let emailFormData: emailFormData = {
      name: this.emailForm.value.name,
      email: this.emailForm.value.email,
      category: this.emailForm.value.category,
      subject: this.emailForm.value.subject,
      details: this.emailForm.value.details
    };

    // console.log('formData: ', emailFormData);
    this.scrollToTop();
    this.emailSentStatus = Status.sending;

    this.http.post('https://us-central1-livin-website-5c1e5.cloudfunctions.net/sendEmail', emailFormData)
      .subscribe((result: any) => {
        // console.log('response: ', result);
        if (result.message === 'Email sent successfully.') {
          // Show Rive Animation
          this.emailSentStatus = Status.sent;
        } else {
          // Show Please Try Again Later Message
          this.emailSentStatus = Status.failed;
        }
      }, (err) => {
          this.emailSentStatus = Status.failed;
      })
  }

  constructor(private http: HttpClient, private fb: FormBuilder, @Inject(PLATFORM_ID) private platformId: any, private title: Title, private meta: Meta) {
    this.siteKey = '6Lf3fKsaAAAAAJc8O5mQ5QpLLvRQpAbrz5-wJaLB';
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      const captchaScript = document.createElement('script');
      captchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
    }

    this.title.setTitle('LIVINGPRINT - Contact Us - Need Help with LIVINGPRINT?');
    this.meta.addTags([
      { name: 'description', content: 'Do you need help using the LIVINGPRINT App or have questions about your order? We are here to help! Feel free to contact us at any time.' }
    ]);
  }

}
