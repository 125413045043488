import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle('LIVINGPRINT - About - We Let You Create Digital Experiences on Postcards with Your Phone Using The LIVINGPRINT App');
    this.meta.addTags([
      { name: 'description', content: 'Want to learn about LIVINGPRINT? You have come to the right place! We let you turn what would have been a lot of driving and expenses into one easy process you can do from your phone! You can include pictures and videos up to 5 minutes total for the slide show. Then your recipient can scan the piece to experience your creation!' }
    ]);
  }
}
