<div class="pageWrapper">
  <div class="textWrapper">
    <h1 class="titleText">Data Deletion</h1>
    <h2 class="subtitleText">Please email help@livingprint.com using the information below.</h2>
    <div class="textSpacer"></div>
    <p class="bodyText">In the email to help@livingprint.com please include your email address that is linked to your
      account. If you created an account with your email, send the email you created the account with. If you logged in
      with another provider such as Google, send us the primary email on that account. Please also include the text "I
      would like all of my data to be permanently deleted from LIVINGPRINT" your request will be handled by the
      LIVINGPRINT team. Thank you.</p>
    <div class="textSpacer"></div>
  </div>
</div>