<div *ngIf="router.url !== '/email-sign-up'">
  <nav class="navbar navbar-expand-lg navbar-light bg-primary siteNavbar tw-z-10">
    <a class="navbar-brand text-white ps-1" style="line-height: 50px; font-size: 1.5rem;" (click)="scrollToTop()" routerLink="/">
      <img src="../assets/livingprintLogo.png" width="50" height="50" class="d-inline-block align-top" alt="LIVINGPRINT Logo">
      LIVINGPRINT
    </a>
    <button class="navbar-toggler me-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end pe-1 tw-pl-5 tw-text-center tw-items-center" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a class="nav-item nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" (click)="scrollToTop()" routerLink="/">Home</a>
        <a class="nav-item nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" (click)="scrollToTop()" routerLink="/contact-us">Contact Us</a>
        <a class="nav-item nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" (click)="scrollToTop()" routerLink="/about">About</a>
        <a class="nav-item nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" (click)="scrollToTop()" routerLink="/faq">FAQ</a>
        <a class="nav-item nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" (click)="scrollToTop()" routerLink="/bulk">Bulk</a>
        <a class="nav-item nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" (click)="scrollToTop()" routerLink="/terms-of-use">Terms of Use</a>
        <a class="nav-item nav-link text-white" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" (click)="scrollToTop()" routerLink="/privacy-policy">Privacy Policy</a>
        <a class="nav-item nav-link text-white d-flex tw-justify-center" href="https://www.instagram.com/livingprintapp" target="_blank">
          <img class="socialIcon tw-pr-2" src="../assets/instagram.svg" alt="Instagram Logo">
          <p class="tw-block md:tw-hidden">Instagram</p>
        </a>
        <a class="nav-item nav-link text-white d-flex tw-justify-center" href="https://www.facebook.com/LivingPrintApp" target="_blank">
          <img class="socialIcon tw-pr-2" src="../assets/facebook.svg" alt="Facebook Logo">
          <p class="tw-block md:tw-hidden">Facebook</p>
        </a>
        <a class="nav-item nav-link text-white d-flex tw-justify-center" href="https://www.youtube.com/channel/UC53y6F7G4LKXOoEfJKGuNGA" target="_blank">
          <img class="socialIcon tw-pr-2" src="../assets/youtube.svg" alt="YouTube Logo">
          <p class="tw-block md:tw-hidden">YouTube</p>
        </a>
        <a class="nav-item nav-link text-white d-flex tw-justify-center" href="https://twitter.com/livingprintapp" target="_blank">
          <img class="socialIcon tw-pr-2" src="../assets/twitter.svg" alt="Twitter Logo">
          <p class="tw-block md:tw-hidden">Twitter</p>
        </a>
      </div>
    </div>
  </nav>
</div>

<div class="sm:tw-pt-16"></div>
<router-outlet></router-outlet>

<div *ngIf="router.url !== '/email-sign-up'" class="footer mt-auto">
  <div class="footerContentWrapper tw-flex">
    <a href="https://www.instagram.com/livingprintapp" target="_blank">
      <img class="socialIcon px-1" src="../assets/instagram.svg" alt="Instagram Logo">
    </a>
    <a href="https://www.facebook.com/LivingPrintApp" target="_blank">
      <img class="socialIcon px-1" src="../assets/facebook.svg" alt="Facebook Logo">
    </a>
    <a href="https://www.youtube.com/channel/UC53y6F7G4LKXOoEfJKGuNGA" target="_blank">
      <img class="socialIcon px-1" src="../assets/youtube.svg" alt="YouTube Logo">
    </a>
    <a href="https://twitter.com/livingprintapp" target="_blank">
      <img class="socialIcon px-1" src="../assets/twitter.svg" alt="Twitter Logo">
    </a>
  </div>
  <p class="copyrightText">© Copyright 2021-2024, AccuZIP Inc.</p>
</div>

<!-- <router-outlet *ngIf="router.url === '/email-sign-up'"></router-outlet> -->
